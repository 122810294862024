import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { GROWERS_DEDUCTIONS } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Deductions",
      "scroll": {
        x: 2e3
      },
      "search-by": "CropCode,Description,ShortDescription,Code",
      "ph-search-by": "Search by Crop Code,Deduct Code, Desc, Short Desc",
      "has-active": false
    }
  }, [_c("text-field", {
    key: "CropCode",
    attrs: {
      "data-index": "cropCode",
      "fixed": "left",
      "sorter": true,
      "title": "Crop Code",
      "width": 104
    }
  }), _c("text-field", {
    key: "Code",
    attrs: {
      "data-index": "code",
      "fixed": "left",
      "sorter": true,
      "title": "Deduct Code",
      "width": 118
    }
  }), _c("text-field", {
    key: "Description",
    attrs: {
      "data-index": "description",
      "sorter": true,
      "title": "Deduct Description"
    }
  }), _c("text-field", {
    key: "ShortDescription",
    attrs: {
      "data-index": "shortDescription",
      "sorter": true,
      "title": "Deduct Short Description"
    }
  }), _c("text-field", {
    key: "SampleTypeCode",
    attrs: {
      "data-index": "sampleTypeCode",
      "sorter": true,
      "title": "Sample Type"
    }
  }), _c("text-field", {
    key: "Sequence",
    attrs: {
      "data-index": "sequence",
      "sorter": true,
      "title": "Sequence"
    }
  }), _c("text-field", {
    key: "JagPrompt",
    attrs: {
      "data-index": "jagPrompt",
      "sorter": true,
      "title": "Scale Prompt"
    }
  }), _c("icon-field", {
    key: "WarningTolerance",
    attrs: {
      "data-index": "warningTolerance",
      "title": "Warning Tolerance",
      "align": "center",
      "render-icon": _vm.renderIcon,
      "sorter": true
    }
  }), _c("text-field", {
    key: "WarningToleranceLimit",
    attrs: {
      "data-index": "warningToleranceLimit",
      "sorter": true,
      "title": "Warning Tolerance Limit"
    }
  }), _c("text-field", {
    key: "WarningText",
    attrs: {
      "data-index": "warningText",
      "sorter": true,
      "title": "Warning Text"
    }
  }), _c("icon-field", {
    key: "ObservedTolerance",
    attrs: {
      "data-index": "observedTolerance",
      "title": "Observed Tolerance",
      "align": "center",
      "render-icon": _vm.renderIcon,
      "sorter": true
    }
  }), _c("text-field", {
    key: "ObservedToleranceLimit",
    attrs: {
      "data-index": "observedToleranceLimit",
      "sorter": true,
      "title": "Observed Tolerance Limit"
    }
  }), _c("text-field", {
    key: "AppliedDeduction",
    attrs: {
      "data-index": "appliedDeduction",
      "sorter": true,
      "title": "Applied Deduction"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "Deductions",
  methods: {
    renderIcon(value) {
      if (value == 0) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Deductions = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.deductions",
      "edit-route": "deductions/:id",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("deductions")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    Deductions
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_DEDUCTIONS,
      menuItems: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "deductions",
          title: "Deductions",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
